import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  SelectionMode,
  addEdge,
  useEdgesState,
  useNodesState,
  useViewport,
  useReactFlow,
} from "reactflow"
import "reactflow/dist/style.css"

import EdgeConnectorNode from "../edgeConnectorNode"
import Edge from "./edge"
import DummyCardNode from "./dummyCardNode"
import StageNode from "./stageNode"
import Spinner from "../../spinner/spinner"
import requestRequestSubmittedTaskCardNode from "./requestRequestSubmittedTaskCardNode"
import inquirySubmittedTaskCardNode from "./inquirySubmittedTaskCardNode"
import workflowRootTaskCardNode from "./workflowRootTaskCardNode"
import { RequestTaskCardNode } from "./requestTaskCardNode"
import { WorkflowTaskCardNode } from "./workflowTaskCardNode"
import WorkflowContextProvider, { useWorkflow } from "./workflowContext"
import { highlightPath, resetNodeStyles } from "./utils"
import approvalChainInitiatedTaskCardNode from "./approvalChainInitiatedTaskCardNode"
import subworkflowInitiatedTaskCardNode from "./subworkflowInitiatedTaskCardNode"
import StageNavigation from "./stageNavigation"
import { IStageNode } from "../../../../utils/types"
import { generateGraph } from "./graphHelper"

export const defaultViewportPosition = { x: 50, y: 100, zoom: 1 }
export const defaultEditable = true

const nodeTypes = {
  dummyCard: DummyCardNode,
  edgeConnector: EdgeConnectorNode,
  requestTaskCard: RequestTaskCardNode,
  stage: StageNode,
  workflowTaskCard: WorkflowTaskCardNode,
  requestRequestSubmittedTaskCard: requestRequestSubmittedTaskCardNode,
  inquirySubmittedTaskCard: inquirySubmittedTaskCardNode,
  workflowRootTaskCard: workflowRootTaskCardNode,
  approvalChainInitiatedTaskCard: approvalChainInitiatedTaskCardNode,
  subworkflowInitiatedTaskCard: subworkflowInitiatedTaskCardNode,
}

const Flow = (props) => {
  const selectedTaskIdParam = new URL(document.location).searchParams.getAll("selected")[0]
  const { initialViewport, height, width, initialNodes, initialEdges, newUrlOnDeselect, ...otherProps } = props
  const { setSelectedTaskId, selectedTaskId, setSelectedTaskRequestStage, setDrawerOpened } = useWorkflow()
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
  const edgeTypes = useMemo(() => ({ edge: Edge }), [])

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds))
    },
    [setEdges],
  )

  const selectedNodes = nodes.filter((node) => node.id === selectedTaskIdParam)
  const stageNodes = nodes.filter((node) => node.type === "stage") as IStageNode[]

  const onSelectionChange = (selectedElements, clearSelection = false) => {
    if (clearSelection) {
      resetNodeStyles(setEdges, setNodes)
      setSelectedTaskId("")
      setSelectedTaskRequestStage("")
      setDrawerOpened(false)

      if (newUrlOnDeselect) {
        history.replaceState({}, null, newUrlOnDeselect)
      }
      return
    }

    const selectedNode = selectedElements.nodes[0]
    if (selectedNode && selectedNode.id !== selectedTaskId) {
      resetNodeStyles(setEdges, setNodes)
      highlightPath(selectedNode, nodes, edges, setEdges, setNodes)
    }
    if (selectedNode) {
      setSelectedTaskId(selectedNode?.id)
      setSelectedTaskRequestStage(selectedNode?.data?.requestStage)
    }
  }

  const { setViewport } = useReactFlow()
  const { x, y, zoom } = useViewport()

  useEffect(() => {
    const currentViewportParams = { x: x, y: y, zoom: zoom }
    setViewport(currentViewportParams)
  }, [x, y, zoom])

  useEffect(() => {
    setViewport(initialViewport)
  }, [setViewport])

  useEffect(() => {
    onSelectionChange({ nodes: selectedNodes })
  }, [])

  useEffect(() => {
    const handleDrawerClose = () => {
      onSelectionChange([], true)
    }

    document.addEventListener("drawer:close", handleDrawerClose)

    return () => {
      document.removeEventListener("drawer:close", handleDrawerClose)
    }
  }, [])

  return (
    <ReactFlow
      defaultViewport={initialViewport}
      onPaneClick={() => {
        resetNodeStyles(setEdges, setNodes)
      }}
      edgeTypes={edgeTypes}
      onSelectionChange={onSelectionChange}
      onConnect={onConnect}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      {...otherProps}
    >
      {stageNodes.length > 0 && <StageNavigation stageNodes={stageNodes} />}
      {props.children}
    </ReactFlow>
  )
}

const NonlinearWorkflow = ({
  viewportPosition,
  delayLoad,
  preventScroll,
  editable,
  workflow,
  subworkflow,
  tasks,
  stages = [],
  requestView = false,
  newUrlOnDeselect = "",
}) => {
  const ref = useRef(null)

  const [isLoading, setIsLoading] = useState(delayLoad)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)
  const [preventScrolling] = useState(preventScroll)
  const initialViewportPosition = viewportPosition || defaultViewportPosition

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 150)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    setHeight(ref.current.offsetHeight)
    setWidth(ref.current.offsetWidth)
  }, [])

  const proOptions = { hideAttribution: true }

  const { nodes, edges } = generateGraph(tasks, stages, requestView)

  return isLoading ? (
    <div className="w-full h-full react-flow" ref={ref}>
      <Spinner />
    </div>
  ) : (
    <div className={"h-full w-full"} ref={ref}>
      <ReactFlowProvider>
        <WorkflowContextProvider editable={editable} isWorkflow={workflow} isSubworkflow={subworkflow}>
          <Flow
            initialNodes={nodes}
            initialEdges={edges}
            onlyRenderVisibleElements={true}
            nodeTypes={nodeTypes}
            proOptions={proOptions}
            height={height}
            width={width}
            preventScrolling={preventScrolling}
            selectionMode={SelectionMode.Full}
            newUrlOnDeselect={newUrlOnDeselect}
            initialViewport={initialViewportPosition}
          >
            <Background color="#aaa" gap={16} />
            <Controls showInteractive={false} />
          </Flow>
        </WorkflowContextProvider>
      </ReactFlowProvider>
    </div>
  )
}

export default NonlinearWorkflow
