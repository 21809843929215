import { Controller } from "@hotwired/stimulus"
import { show } from "../utils"

// Connects to data-controller="document-type-field-with-ai-indication"
export default class extends Controller {
  static targets = [
    "aiIndication",
    "mediumConfidenceDocumentTypeConfirmation",
    "documentTypeSelectWrapper",
    "documentTypeSelect",
  ]
  static values = {
    mediumConfidenceDocumentTypeId: String,
  }

  mediumConfidenceDocumentTypeIdValue: string
  aiIndicationTarget?: HTMLElement
  mediumConfidenceDocumentTypeConfirmationTarget?: HTMLElement
  documentTypeSelectTarget?: HTMLSelectElement
  documentTypeSelectWrapperTarget?: HTMLSelectElement

  connect() {}

  onDocumentTypeSelectChange() {
    if (this.hasAiIndicationTarget) {
      this.aiIndicationTarget.remove()
    }
  }

  onConfirmMediumConfidenceDocTypeSuggestion() {
    this.mediumConfidenceDocumentTypeConfirmationTarget.remove()
    this.documentTypeSelectTarget.value = this.mediumConfidenceDocumentTypeIdValue
    this.documentTypeSelectTarget.classList.remove("is-empty")
    show(this.documentTypeSelectWrapperTarget)
  }

  onRejectMediumConfidenceDocTypeSuggestion() {
    this.mediumConfidenceDocumentTypeConfirmationTarget.remove()
    this.documentTypeSelectTarget.value = ""
    this.documentTypeSelectTarget.classList.add("is-empty")
    show(this.documentTypeSelectWrapperTarget)
  }
}
