import Dagre from "@dagrejs/dagre"
import { getVisualBuilderCardDimensions } from "../../../../utils"

const DAGRE_EDGE_SEP = 20
const DAGRE_NODE_SEP = 40
const DAGRE_RANK_SEP = 60
const DAGRE_SETTINGS = {
  rankdir: "LR",
  align: "UL",
  edgesep: DAGRE_EDGE_SEP,
  nodesep: DAGRE_NODE_SEP,
  ranksep: DAGRE_RANK_SEP,
}
const ROOT_IDS = ["request_submitted", "root"]
const POSITION_MULTIPLIER = 8
const JUMP_TO_VIEWPORT_MARGIN = 50

const position = ({ x, y }) => ({ x: x * POSITION_MULTIPLIER, y: y * POSITION_MULTIPLIER })

const jumpToViewport = (stage) => ({
  x: -stage.x + JUMP_TO_VIEWPORT_MARGIN,
  y: stage.y + 250,
  zoom: 1,
})

const stageNode = (stage, stages) => ({
  id: stage.id,
  type: "stage",
  data: {
    name: stage.id,
    jumpToViewport: jumpToViewport(stage),
    dropdownName: stage.id,
    order: stages.indexOf(stage.id),
  },
  position: { x: stage.x, y: stage.y },
  sourcePosition: "right",
  targetPosition: "left",
  draggable: false,
  connectable: false,
  selectable: false,
})

const rootNodeType = (task, requestView) => {
  if (requestView) {
    if (task.id === "root") {
      return "inquirySubmittedTaskCard"
    } else {
      return "requestRequestSubmittedTaskCard"
    }
  }

  return "workflowRootTaskCard"
}

const rootNode = (task, requestView) => ({
  id: task.id,
  position: position(task),
  data: task.data,
  sourcePosition: "right",
  targetPosition: "left",
  zIndex: 2,
  draggable: false,
  connectable: false,
  selectable: true,
  type: rootNodeType(task, requestView),
})

const taskNode = (task, requestView) => {
  const { data } = task

  if (ROOT_IDS.includes(task.id)) {
    return rootNode(task, requestView)
  }

  return {
    id: task.id,
    sourcePosition: "right",
    targetPosition: "left",
    data,
    position: position(task),
    zIndex: 2,
    draggable: true,
    connectable: false,
    selectable: true,
    type: requestView ? "requestTaskCard" : "workflowTaskCard",
  }
}

const generateEdges = (tasks) => {
  const taskEdges = tasks.flatMap((task) => {
    const triggers = task.data.triggers || []

    return triggers.map((trigger) => ({
      id: `${trigger.id}-${task.id}`,
      source: trigger.id,
      target: task.id,
      type: "step",
      data: {
        requestStage: task.requestStage,
        taskId: task.id,
      },
      pathOptions: {
        offset: 0,
        borderRadius: 20,
      },
      style: {
        stroke: "#6A778A",
        strokeWidth: 2,
      },
      markerEnd: {
        type: "arrowclosed",
        color: "#6A778A",
      },
    }))
  })

  return taskEdges
}

const generateStageNodes = (taskNodes, stages) => {
  const stageNodes = stages.map((stage) => {
    const tasks = taskNodes.filter((task) => task.data.requestStage === stage)
    const leftMostTask = tasks.reduce((acc, task) => (task.position.x < acc.position.x ? task : acc), tasks[0])

    const x = leftMostTask.position.x
    const y = -100

    return stageNode({ id: stage, x, y }, stages)
  })

  return stageNodes
}

const arrangeNodes = (nodes, stages) => {
  stages.slice(1).forEach((stage) => {
    const previousStage = stages[stages.indexOf(stage) - 1]
    const previousStageMostRightNode = nodes.reduce((acc, node) => {
      if (node.data.requestStage === previousStage && node.position.x > acc.position.x) {
        return node
      }
      return acc
    })

    const previousRightMostX = previousStageMostRightNode.position.x
    const firstNodeOfStage = nodes.find((node) => node.data.requestStage === stage)
    const firstNodeOfStageX = firstNodeOfStage.position.x
    const XStep = previousRightMostX - firstNodeOfStageX

    const { width: cardWidth } = getVisualBuilderCardDimensions(true)

    const stageNodes = nodes.filter((node) => node.data.requestStage === stage)
    stageNodes.forEach((node) => {
      node.position.x = node.position.x + XStep + cardWidth + DAGRE_EDGE_SEP + DAGRE_NODE_SEP + DAGRE_RANK_SEP
    })
  })

  return nodes
}

export const generateGraph = (tasks, stages = [], requestView = false) => {
  const g = new Dagre.graphlib.Graph()
  g.setGraph(DAGRE_SETTINGS).setDefaultEdgeLabel(() => ({}))

  tasks.forEach((task) => g.setNode(task.id, task))

  const edges = generateEdges(tasks)

  edges.forEach((edge) => g.setEdge(edge.source, edge.target))

  Dagre.layout(g)

  const taskNodes = tasks.map((task) => taskNode(g.node(task.id), requestView))

  if (stages.length > 1) {
    arrangeNodes(taskNodes, stages)
    const stageNodes = generateStageNodes(taskNodes, stages)
    return { nodes: [...taskNodes, ...stageNodes], edges }
  }

  return { nodes: taskNodes, edges }
}
