import { Controller } from "@hotwired/stimulus"
import ModalController from "./modal_controller"
import { hide, show } from "../utils"

// Connects to data-controller="contract-undetermined-modal"
export default class extends Controller {
  static targets = [
    "endDateInput",
    "endDateNotApplicableInput",
    "startDateInput",
    "modalWrapper",
    "renewalTypeInput",
    "stageInput",
    "termInput",
    "reasons",
    "terminationDateInput",
  ]
  static values = {
    originalStatus: String,
    originalStage: String,
    confirmationCompleted: Boolean,
    renewalTypeEnabled: Boolean,
  }

  endDateInputTarget: HTMLInputElement
  endDateNotApplicableInputTarget: HTMLInputElement
  startDateInputTarget: HTMLInputElement
  modalWrapperTarget: HTMLDivElement
  hasRenewalTypeInputTarget: boolean
  hasModalWrapperTarget: boolean
  hasStageInputTarget: boolean
  hasTermInputTarget: boolean
  hasEndDateInputTarget: boolean
  renewalTypeInputTarget: HTMLSelectElement
  stageInputTarget: HTMLSelectElement
  termInputTarget: HTMLSelectElement
  reasonsTargets: HTMLLIElement[]
  terminationDateInputTarget: HTMLInputElement
  hasTerminationDateInputTarget: boolean

  originalStatusValue: string
  originalStageValue: string
  renewalTypeEnabledValue: boolean
  confirmationCompletedValue: boolean
  _modalController: ModalController
  private originatingEventTarget: HTMLElement

  get modalController() {
    if (this.hasModalWrapperTarget && !this._modalController) {
      let modalNode: HTMLElement = this.modalWrapperTarget.querySelector('[data-controller="modal"]')
      this._modalController = this.application.getControllerForElementAndIdentifier(
        modalNode,
        "modal",
      ) as ModalController
    }
    return this._modalController
  }

  get modalElement(): HTMLElement {
    return this.modalWrapperTarget.querySelector('[data-controller="modal"]')
  }

  clickSave(ev: CustomEvent) {
    this.originatingEventTarget = <HTMLElement>ev.target

    // Bypass modal if either:
    // 1. The modal was already confirmed and this is the second click, which should propagate
    // 2. The form does not have the relevant fields, in which case we don't expect the contract to lose its status
    if (this.confirmationCompletedValue || !this.canDetermineStatus()) {
      this.modalController.hide()
      this.confirmationCompletedValue = false
      return true
    }

    if (!this.modalController) {
      return
    }

    if (this.statusWasDetermined() && this.statusWillBeUndetermined()) {
      this.modalController.show()
      this.modalElement.dataset.pending = "true"
      ev.stopImmediatePropagation()
      ev.preventDefault()
      this.displayReasons()
      return false
    }
    this.modalController.hide()
    return true
  }

  private statusWasDetermined() {
    return this.originalStatusValue !== "undetermined"
  }

  private canDetermineStatus() {
    if (this.renewalTypeEnabledValue) {
      return this.hasRenewalTypeInputTarget
    } else {
      return this.hasTermInputTarget && this.hasEndDateInputTarget
    }
  }

  private statusWillBeUndetermined() {
    if (this.hasTerminationDateInputTarget && new Date(this.terminationDateInputTarget.value) <= new Date()) {
      return false
    } else if (
      this.renewalTypeEnabledValue &&
      (this.renewalTypeInputTarget.value === "automatic" || this.renewalTypeInputTarget.value === "optional")
    ) {
      return this.endDateInputTarget.value === ""
    } else if (this.renewalTypeEnabledValue && this.renewalTypeInputTarget.value === "perpetual") {
      return this.startDateInputTarget.value === ""
    } else if (this.renewalTypeEnabledValue && this.renewalTypeInputTarget.value === "non_renewing") {
      return this.endDateInputTarget.value === "" && !this.endDateNotApplicableInputTarget.checked
    } else {
      return (
        this.hasTermInputTarget &&
        this.hasEndDateInputTarget &&
        this.termInputTarget.value !== "month_to_month" &&
        this.endDateInputTarget.value === ""
      )
    }
  }

  private hideReasons() {
    this.reasonsTargets.forEach((reason) => {
      hide(reason)
    })
  }

  private displayReasons() {
    this.hideReasons()
    if (
      this.renewalTypeEnabledValue &&
      (this.renewalTypeInputTarget.value === "automatic" || this.renewalTypeInputTarget.value === "optional") &&
      this.endDateInputTarget.value === ""
    ) {
      const reasonEl = this.getReasonEl("end_date")
      reasonEl && show(reasonEl)
    } else if (
      this.renewalTypeEnabledValue &&
      this.renewalTypeInputTarget.value === "perpetual" &&
      this.startDateInputTarget.value === ""
    ) {
      const reasonEl = this.getReasonEl("start_date")
      reasonEl && show(reasonEl)
    } else if (
      this.renewalTypeEnabledValue &&
      this.renewalTypeInputTarget.value === "non_renewing" &&
      this.endDateInputTarget.value === "" &&
      !this.endDateNotApplicableInputTarget.checked
    ) {
      const reasonEl = this.getReasonEl("end_date")
      reasonEl && show(reasonEl)
    }
    this.modalElement.dataset.pending = "false"
  }

  doSave() {
    if (!this.modalController) {
      return
    }
    this.modalController.hide()
    if (this.hasStageInputTarget) {
      this.stageInputTarget.value = ""
    }
    this.confirmationCompletedValue = true
    this.originatingEventTarget.click()
  }

  doClose(ev: CustomEvent) {
    if (!this.modalController) {
      return
    }
    ev.preventDefault()
    this.modalController.hide()
  }

  private getReasonEl(value: string) {
    let reasonEl = null
    this.reasonsTargets.forEach((reason) => {
      if (reason.dataset.reason === value) {
        reasonEl = reason
        return
      }
    })
    return reasonEl
  }
}
